import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateContact as onUpdateContact,
  getContactMega as onGetContact,
} from "store/contacts/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EcommerceAddProduct = () => {
  document.title = "İletişim Bilgileri | KTO Admin Paneli";
  const dispatch = useDispatch();
  const [getContactEdit, setContactEdit] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: (getContactEdit && getContactEdit.Email_Adress) || '',
      gsmNumber: (getContactEdit && getContactEdit.Phone_Numer) || '',
      adress: (getContactEdit && getContactEdit.Adress_Text) || '',
      facebook:(getContactEdit && getContactEdit.Facebook_Adress) || '',
      instagram:(getContactEdit && getContactEdit.Instagram_Adress) || '',
      twitter:(getContactEdit && getContactEdit.Twitter_Adress) || '',
    },
    validationSchema: Yup.object({
      adress: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        Email_Adress: values.email,
        Phone_Numer: values.gsmNumber,
        Adress_Text: values.adress,
        Facebook_Adress:values.facebook,
        Instagram_Adress:values.instagram,
        Twitter_Adress:values.twitter

      };


      dispatch(onUpdateContact(updateOrder));
      validation.resetForm();

      dispatch(onGetContact());

      showToast(true)

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetContact());

    }
  }, [dispatch, users]);

  useEffect(() => {
    setContactEdit(users[0]);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);


  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellendi"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="İletişim Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>İletişim Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Adres</Label>

                        <textarea
  id="adress"
  name="adress"
  className="form-control"
  placeholder="Başlık"
  onChange={validation.handleChange}
  onBlur={validation.handleBlur}
  value={validation.values.adress || ""}
  style={{ border: validation.touched.adress && validation.errors.adress ? "1px solid red" : "1px solid black" }}

  rows={4} // isteğe bağlı olarak başlangıçta kaç satır gösterileceğini belirleyebilirsiniz
/>

                     
                        {validation.touched.adress && validation.errors.adress ? (
                          <FormFeedback type="invalid">{validation.errors.adress}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="price">Telefon Numarası</Label>
                        <Input
                          id="gsmNumber"
                          name="gsmNumber"
                          type="text"
                          className="form-control"
                          placeholder="Telefon Numarası"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gsmNumber || ""}
                          invalid={
                            validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                          }
                        />
                        {validation.touched.gsmNumber && validation.errors.adgsmNumberess ? (
                          <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="price">Eposta</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Eposta Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="price">Facebook Adresi</Label>
                        <Input
                          id="facebook"
                          name="facebook"
                          type="text"
                          className="form-control"
                          placeholder="Facebook Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.facebook || ""}
                          invalid={
                            validation.touched.facebook && validation.errors.facebook ? true : false
                          }
                        />
                        {validation.touched.facebook && validation.errors.facebook ? (
                          <FormFeedback type="invalid">{validation.errors.facebook}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="price">Twitter Adresi</Label>
                        <Input
                          id="twitter"
                          name="twitter"
                          type="text"
                          className="form-control"
                          placeholder="Twitter Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.twitter || ""}
                          invalid={
                            validation.touched.twitter && validation.errors.twitter ? true : false
                          }
                        />
                        {validation.touched.twitter && validation.errors.twitter ? (
                          <FormFeedback type="invalid">{validation.errors.twitter}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="price">Instagram Adresi</Label>
                        <Input
                          id="instagram"
                          name="instagram"
                          type="text"
                          className="form-control"
                          placeholder="Instagram Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.instagram || ""}
                          invalid={
                            validation.touched.instagram && validation.errors.instagram ? true : false
                          }
                        />
                        {validation.touched.instagram && validation.errors.instagram ? (
                          <FormFeedback type="invalid">{validation.errors.instagram}</FormFeedback>
                        ) : null}

                      </div>


                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
