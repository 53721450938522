import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import TableContainerAuth from "../../../components/Common/TableContainerAuth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status} from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getAuthority as onGetAuthority,
  addNewUser as onAddNewUser,
  updateAuth as onUpdateAuth,
  deleteStudyProject as onDeleteStudyProject,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';

const ContactsList = props => {

  //meta title
  document.title = "Proje Çalışmaları | TTO ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      authName: (contactEdit && contactEdit.Authority_Text) || "",
   
    },
    validationSchema: Yup.object({
      authName: Yup.string().required("Rol Adını Giriniz")
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          NameSurname:values.name,
          Authority_Text:values.authName,
       //   Users_IsActive:toggleSwitch
        };


        dispatch(onUpdateAuth(updateUser));
        setIsEdit(false);
        validation.resetForm();

      } else {
        const newUser = {
          Authority_Text: values["authName"]
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.authlist,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAuth, setModalAuth] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditAuth, setEditAuth] = useState(false);

  
  const [getAuthList, setAuthList] = useState([]);
  const [getAllAuth, setAllAuth] = useState([]);
  const [getUserAuth, setUserAuth] = useState([]);


  const openClick = (cell) => {

    let filterData = getAuthList.filter((x)=> x.ID == cell.row.original.ID)


    if (filterData.length == 0){
      setAuthList(oldArray => [...oldArray,cell.row.original] );
    }else{

    }

  };

  const openClickUser = (cell) => {
    console.log("adsasd",cell.row.original)

    let filterData = getAuthList.filter((x)=> x.ID == cell.row.original.ID )

    console.log("adsasd",filterData)

    if (filterData.length == 0){
      setAuthList(oldArray => [...oldArray,cell.row.original] );
    }else{

    }

  };

  const columnsUserAuth = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return <input onClick={()=> openClickUser(cellProps)} type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Öğrenci Adı",
        accessor: "Study_Check_Project",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Departmanı",
        accessor: "Universty_Departments.Departments_Name",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      }
    
    ],
    []
  );

  const columnsAuth = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return <input onClick={()=> openClick(cellProps)} type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Öğrenci Adı",
        accessor: "Study_Name_Surname",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Öğrenci Adı",
        accessor: "Study_Check_Project",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Departmanı",
        accessor: "Universty_Departments.Departments_Name",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      }
      
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      
      {
        Header: "Öğrenci Adı",
        accessor: "Study_Name_Surname",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Aktif Mi",
        accessor: "Study_Check_Project",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Departmanı",
        accessor: "Universty_Departments.Departments_Name",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">



              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAuthority());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleAuth = () => {
    setModalAuth(!modalAuth);
  };

  

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteStudyProject(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Proje Çalışmaları" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddAuthList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

           
                  
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Üye Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Rol Adı</Label>
                              <Input
                                name="authName"
                                label="Rol Adı"
                                type="text"
                                placeholder="Rol Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.authName || ""}
                                invalid={
                                  validation.touched.authName &&
                                    validation.errors.authName
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.authName &&
                                validation.errors.authName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.authName}
                                </FormFeedback>
                              ) : null}
                            </div>
                         
                          </Col>
                        </Row>
                        <Row>

                  
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
